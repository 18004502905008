// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$dms-v4-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$blue-palette,
    ),
    density: (
      scale: 0,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($dms-v4-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($dms-v4-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($dms-v4-theme);
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
html {
  background-color: #f7f7f7;
  color: #404654 !important;
  opacity: 1;
  background-image: linear-gradient(#eee 2.1px, transparent 2.1px),
    linear-gradient(to right, #eee 2.1px, #f7f7f7 2.1px);
  background-size: 50px 50px;
}

.cdk-overlay-pane:not(.mat-mdc-dialog-panel) {
  @include mat.elevation(12);
  @apply rounded-2xl;
}

.info-svg {
  filter: invert(63%) sepia(64%) saturate(3043%) hue-rotate(146deg)
    brightness(93%) contrast(95%);
}

.success-svg {
  filter: invert(59%) sepia(53%) saturate(392%) hue-rotate(98deg)
    brightness(99%) contrast(94%);
}

.error-svg {
  filter: invert(56%) sepia(53%) saturate(1865%) hue-rotate(309deg)
    brightness(99%) contrast(104%);
}

.white-svg {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%)
    contrast(100%);
}

.grey-svg {
  filter: invert(59%) sepia(17%) saturate(7%) hue-rotate(358deg) brightness(89%)
    contrast(86%);
}

.body-svg {
  filter: invert(24%) sepia(34%) saturate(297%) hue-rotate(183deg)
    brightness(93%) contrast(86%);
}

.primary-svg {
  filter: invert(64%) sepia(10%) saturate(6952%) hue-rotate(188deg)
    brightness(98%) contrast(99%);
}

// https://codepen.io/sosuke/pen/Pjoqqp

::-webkit-scrollbar {
  width: 8px; /* Largeur de la scrollbar */
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #eff6ff; /* Couleur de fond de la piste de la scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #bed7f5; /* Couleur de la scrollbar */
  border-radius: 4px; /* Bords arrondis */
}

::-webkit-scrollbar-thumb:hover {
  background: #7fb1ee; /* Couleur de la scrollbar au survol */
}

/* Styles pour Firefox */
.scrollbar-custom {
  scrollbar-width: thin; /* Largeur de la scrollbar */
  scrollbar-color: #60a5fa #eff6ff; /* Couleur de la scrollbar et de la piste */
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  //font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;

  :root {
    font-family: "Libre Franklin", sans-serif;
  }

  * {
    font-family: "Libre Franklin", sans-serif;
  }
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 300ms ease-in-out;
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 200ms ease-in-out;
}

.cdk-drag-placeholder {
  opacity: 0.1; /* Masquer l'élément en cours de déplacement */
  max-width: 300px;
}

app-uvc-modal {
  border: 0 !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Libre Franklin", sans-serif;
}

.required::after {
  content: "*";
  @apply text-error;
  margin-left: 5px;
}

.mat-mdc-menu-content {
  padding: 0 !important;
  @apply rounded-lg;
}

.mat-mdc-menu-panel {
  background: transparent !important;
  @apply rounded-lg;
  box-shadow: unset !important;
}
